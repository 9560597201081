<template>
  <div class="rightInfoForm-container">
    <form-panel v-if="pageType !== 'check'" class="form-container" ref="formPanel" :form="form"  v-bind="submitConfig" :submitUrl="submitUrl" submitText="保存" :submitBefore="submitBefore" :submitSuccess="submitSuccess">
      <col2-detail>
        <col2-block title="基本信息">
          <el-form-item label="户主" :rules="[{ required: true, message: '请选择所属项目', trigger: 'blur' }]" prop="communityId">
            <v-select2
              v-model="form.houseId"
              :placeholder="placeholder"
              v-bind="userParams"
              :disabled="nameDisabled"
              @onChange="userChange"
              :echoRequestData="echohouseId"
              :width="350"
            ></v-select2>
          </el-form-item>
          <el-form-item label="所属项目" :rules="[{ required: true, message: '请选择所属项目', trigger: 'blur' }]" prop="communityId">
            <v-select2 ref="select2" :width="350" :subjoinChangeClear="true" disabled v-model="form.communityId" v-bind="communityParams" placeholder="查询项目" @onChange="handleCommunityChange"/>
            <!-- <div>{{form.communityName}}</div> -->
          </el-form-item>
          <!-- <el-form-item label="户主" :rules="[{ required: true, message: '请输入户主', trigger: 'blur' }]" prop="userName">
            <v-input placeholder="请输入户主" v-model="form.userName"></v-input>
          </el-form-item> -->
          <!-- <el-form-item label="身份证号码" :rules="[{ required: false, message: '请输入身份证号码', trigger: 'blur' }]" prop="cardNum">
            <v-input :width="350" disabled v-model="form.cardNum"></v-input>
          </el-form-item> -->
          <el-form-item label="手机号码" :rules="[{ required: false, message: '请输入身份证号码', trigger: 'blur' }]" prop="cardNum">
            <v-input :width="350" disabled v-model="form.mobileNum"></v-input>
            <!-- <div>{{form.cardNum}}</div> -->
          </el-form-item>
          <el-form-item label="承包家庭" :rules="[{ required: true, message: '请输入承包家庭', trigger: 'blur' }]" prop="houseAddress">
            <v-input :width="350" disabled v-model="form.houseAddress"></v-input>
            <!-- <div>{{form.houseAddress}}</div> -->
          </el-form-item>
          <el-form-item label="发包方" :rules="[{ required: true, message: '请选择发包方', trigger: 'blur' }]" prop="contractor">
            <!-- <v-select :width="350" disabled v-model="form.contractor" :options="contractType"></v-select> -->
            <v-input :width="350" disabled v-model="form.contractor"></v-input>
            <!-- <div>{{form.communityName}}</div> -->
          </el-form-item>
          <el-form-item label="承包方式" :rules="[{ required: true, message: '请选择承包方式', trigger: 'blur' }]" prop="contractorType">
            <v-select :width="350" v-model="form.contractorType" :options="contractType"></v-select>
          </el-form-item>
          <el-form-item label="合同代码" :rules="[{ required: true, message: '请输入合同代码', trigger: 'blur' }]" prop="contractCode">
            <v-input :width="350" placeholder="请输入合同代码" v-model="form.contractCode"></v-input>
          </el-form-item>
          <el-form-item label="承包期限" :rules="[{ required: true, message: '请输入承包期限', validator: contractTime, trigger: 'blur' }]" prop="contractTime">
            <v-datepicker :startTime.sync="form.contractBeginTime" :endTime.sync="form.contractEndTime"></v-datepicker>
          </el-form-item>
          <el-form-item label="承包总面积" :rules="[{ required: true, message: '请输入承包总面积', trigger: 'blur' }]" prop="contractAreaHectare">
            <span>{{form.contractAreaHectare ? form.contractAreaHectare + ' 公顷 ' : ''}}</span>
            <span>{{form.contractAreaMu ? form.contractAreaMu + ' 亩 ' : ''}}</span>
            <span>{{form.contractAreaFen ? form.contractAreaFen + ' 分 ' : ''}}</span>
            <span>{{form.contractAreaLi ? form.contractAreaLi + ' 厘 ' : ''}}</span>
            <span>{{form.contractAreaHao ? form.contractAreaHao + ' 毫 ' : ''}}</span>
          </el-form-item>
          <el-form-item label="承包总块数" :rules="[{ required: true, message: '请输入承包总块数', trigger: 'blur' }]" prop="contractNum">
            <div>{{form.contractNum ? form.contractNum + ' 块' : ''}}</div>
          </el-form-item>
          <el-form-item label="承包地块信息" :rules="[{ required: true, message: '请选择承包地块信息', trigger: 'blur' }]" prop="landInfoIds">
            <chosen-list-panel :list.sync="form.landInfoIds" :disabled="!form.houseId" @select="onSelect" @change="onChange"/>
          </el-form-item>
        </col2-block>
      </col2-detail>
    </form-panel>

    <div class="check-wrapper" v-else>
      <v-button class="check-back" text="返回" type="primary" @click="$router.back()"></v-button>
      <col2-detail>
        <col2-block title="确权信息">
          <col2-item :span="24" label="所属项目" :text="form.communityName" />
          <col2-item :span="24" label="户主" :text="form.userName" />
          <!-- <col2-item :span="24" label="身份证号码" :text="form.cardNum" /> -->
          <col2-item :span="24" label="手机号码" :text="form.mobileNum" />
          <col2-item :span="24" label="承包家庭" :text="form.houseAddress" />
          <col2-item :span="24" label="发包方" :text="form.contractor" />
          <col2-item :span="24" label="承包方式" :text="contractorTypeMap[form.contractorType]" />
          <col2-item :span="24" label="合同代码" :text="form.contractCode" />
          <col2-item :span="24" label="承包期限" :text="`${form.contractBeginTime ? form.contractBeginTime + ' 至 ': ''}${form.contractEndTime}`" />
          <col2-item :span="24" label="承包总面积（亩）" :text="form.contractArea" />
          <col2-item :span="24" label="承包总块数（块）" :text="form.contractNum" />
        </col2-block>
        <col2-block title="承包地块信息">
          <!-- <list
            ref="list"
            :searchUrl="checkSearchUrl"
            :searchParams.sync="checkSearchParams"
            :headers="checkHeaders"
            :hasOperateColumn="false"
          ></list> -->
          <el-table
            class="table"
            :data="form.siteNameList"
            border
            style="width: 70%">
            <el-table-column
              prop="communityName"
              label="所属项目"
              width="100">
            </el-table-column>
            <el-table-column
              prop="blockOfCode"
              label="地块代码"
              width="180">
            </el-table-column>
            <el-table-column
              prop="siteName"
              label="地块名称">
            </el-table-column>
            <el-table-column
              prop="plotCategory"
              label="地块类型">
              <template slot-scope="scope">
                <span>{{ landTypeMap[Number(scope.row.plotCategory)]}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="madeByArea"
              label="面积">
              <template slot-scope="scope">
                <span>{{calcArea(scope.row)}}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="beLocated"
              label="坐落（四至）">
            </el-table-column>
            <el-table-column
              prop="authenticRight"
              label="确权状态">
              <template slot-scope="scope">
                <span>{{ scope.row.authenticRight === 0 ? '未确权' : '已确权'}}</span>
              </template>
            </el-table-column>
          </el-table>
        </col2-block>
      </col2-detail>
    </div>
    <multi-select
      ref="multiSelect"
      :isShow.sync="isShow"
      :responseKey="responseKey"
      :searchUrl="searchUrl"
      :backFill="selectedData"
      :searchParams="searchParams"
      :responseParams="responseParams"
      :extraParams="extraParams"
      :selectable="selectable"
      :isMultiSelect="true"
      :headers="headers"
      title="项目列表"
      update:backFill="backFill"
      @callback="success"
      @close="close"
    >
      <template #searchSlot>
        <v-select2 label="所属项目" :width="180" v-model="searchParams.communityId" v-bind="communityParams"></v-select2>
        <v-input label="地块代码" v-model="searchParams.blockOfCode"></v-input>
        <v-input label="地块名称" v-model="searchParams.siteName"></v-input>
        <v-select label="地块类型" v-model="searchParams.plotCategory" :options="landType"></v-select>
        <v-select label="确权状态" v-model="searchParams.authenticRight" :options="RightConfirStatus"></v-select>
        <v-input label="户主" v-model="searchParams.userName"></v-input>
        <v-input label="承包家庭" v-model="searchParams.houseAddress"></v-input>
        <v-datepicker
          label="创建时间"
          :startTime.sync="searchParams.intimeStart"
          :endTime.sync="searchParams.intimeEnd"
          :appendToBody="appendToBody"
        ></v-datepicker>
      </template>
    </multi-select>

    <v-map
      :selectedLng="Number(form.longitude)"
      :selectedLat="Number(form.latitude)"
      :showMapDialog.sync="showMapDialog"
      @setLngAndLat="setLngAndLat"
    ></v-map>
  </div>
</template>
<script>
import _ from 'lodash'
import { contractType, landType, landTypeMap, RightConfirStatus, contractorTypeMap } from './map'
import { Col2Detail, Col2Block, Col2Item, MultiSelect, ChosenListPanel } from 'components/bussiness'
import { vMap } from 'components/control'
import { communityParams } from 'common/select2Params'
import { getRightInfo, getLandList, editRightInfo, addRightInfo, getOwnerListURL, getIdNum } from './api'
export default {
  name: 'landInfo',
  components: {
    MultiSelect,
    Col2Detail,
    Col2Block,
    Col2Item,
    vMap,
    ChosenListPanel
  },
  data () {
    return {
      extraParams: {},
      userParams: {
        searchUrl: getOwnerListURL,
        request: {
          text: "ownerName",
          value: 'houseId'
        },
        response: {
          value: "houseId",
          text: "name",
        },
      },
      communityParams,
      contractorTypeMap,
      landTypeMap,
      checkSearchUrl: getLandList,
      checkSearchParams: {
        landInfoIds: []
      },
      lwidth: 300,
      width: 150,
      personHead: [],
      submitConfig: {},
      submitUrl: editRightInfo,
      form: {
        communityId: '',
        userName: '',
        cardNum: '',
        houseAddress: '',
        contractor: undefined,
        contractorType: undefined,
        contractCode: '',
        contractBeginTime: '',
        contractEndTime: '',
        contractAreaHectare: '',
        contractAreaMu: '',
        contractAreaFen: '',
        contractAreaLi: '',
        contractAreaHao: '',
        contractNum: '',
        landInfoIds: [],
        mobileNum: ''
      },
      showMapDialog: false,
      appendToBody: false,
      contractType,
      pageType: '',
      isShow: false,
      responseKey: {
        id: 'id',
        name: 'siteName'
      },
      searchUrl: getLandList,
      selectedData: [],
      searchParams: {
        intimeStart: '', // 创建开始时间
        intimeEnd: '', // 创建结束时间
        blockOfCode: '', // 地块代码
        siteName: '', // 地块名称
        plotCategory: undefined, // 地块类型
        authenticRight: 0, // 确权状态
        communityId: '', // 项目ID
        userName: '', // 户主
        houseAddress: '', // 承包家庭
      },
      responseParams: {
        id: 'id',
        name: 'blockOfCode'
      },
      headers: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'blockOfCode',
          label: '地块代码'
        },
        {
          prop: 'siteName',
          label: '地块名称'
        },
        {
          prop: 'plotCategory',
          label: '地块类型',
          formatter: row => landTypeMap[row.plotCategory]
        },
        {
          prop: 'madeByArea',
          label: '面积',
          formatter: row => {
            const { madeByAreaHectare, madeByAreaLi, madeByAreaMu, madeByAreaHao, madeByAreaFen} = row
            const Hectare = Number(madeByAreaHectare) !== 0 ? `${Number(madeByAreaHectare)}公顷` : ''
            const Li = Number(madeByAreaLi) !== 0 ? `${Number(madeByAreaLi)}厘` : ''
            const Mu = Number(madeByAreaMu) !== 0 ? `${Number(madeByAreaMu)}亩` : ''
            const Hao = Number(madeByAreaHao) !== 0 ? `${Number(madeByAreaHao)}毫` : ''
            const Fen = Number(madeByAreaFen) !== 0 ? `${Number(madeByAreaFen)}分` : ''
            return Hectare + Mu + Fen + Li + Hao
          }
        },
        {
          prop: 'beLocatedList',
          label: '坐落（四至）',
          formatter: row => {
            const [east, west, south, north]  = row.beLocatedList
            return `东至${east}, 西至${west}, 南至${south}, 北至${north}`
          }
        },
        {
          prop: 'authenticRight',
          label: '确权状态',
          formatter: row => row.authenticRight === 0 ? '未确权' : '已确权'
        },
        {
          prop: 'houseAddress',
          label: '承包家庭'
        },
        {
          prop: 'userName',
          label: '户主'
        },
        {
          prop: 'intime',
          label: '创建时间'
        },
        {
          prop: 'updateTime',
          label: '修改时间'
        },
        {
          prop: 'updateUser',
          label: '操作人'
        }
      ],
      checkHeaders: [
        {
          prop: 'communityName',
          label: '所属项目'
        },
        {
          prop: 'blockOfCode',
          label: '地块代码'
        },
        {
          prop: 'siteName',
          label: '地块名称'
        },
        {
          prop: 'plotCategory',
          label: '地块类型',
          formatter: row => landTypeMap[row.plotCategory]
        },
        {
          prop: 'madeByArea',
          label: '面积',
          formatter: row => {
            const { madeByAreaHectare, madeByAreaLi, madeByAreaMu, madeByAreaHao, madeByAreaFen} = row
            const Hectare = Number(madeByAreaHectare) !== 0 ? `${Number(madeByAreaHectare)}公顷` : ''
            const Li = Number(madeByAreaLi) !== 0 ? `${Number(madeByAreaLi)}厘` : ''
            const Mu = Number(madeByAreaMu) !== 0 ? `${Number(madeByAreaMu)}亩` : ''
            const Hao = Number(madeByAreaHao) !== 0 ? `${Number(madeByAreaHao)}毫` : ''
            const Fen = Number(madeByAreaFen) !== 0 ? `${Number(madeByAreaFen)}分` : ''
            return Hectare + Mu + Fen + Li + Hao
          }
        },
        {
          prop: 'beLocatedList',
          label: '坐落（四至）',
          formatter: row => {
            const [east, west, south, north]  = row.beLocatedList
            return `东至${east}, 西至${west}, 南至${south}, 北至${north}`
          }
        },
        {
          prop: 'authenticRight',
          label: '确权状态',
          formatter: row => row.authenticRight === 0 ? '否' : '是'
        }
      ],
      RightConfirStatus,
      landType,
      mount: true,
      placeholder: '请输入户主',
      nameDisabled: true,
      houseId: ''
    }
  },
  mounted () {
    const { id, type, houseId } = this.$route.query
    this.pageType = type
    this.houseId = houseId
    if (type !== 'add') {
      this.handleGetRightFormData(id)
      this.placeholder = '加载中...'
    } else {
      this.submitUrl = addRightInfo
      this.nameDisabled = false
    }
  },
  methods: {
    submitBefore (data) {
      let landInfoIds = data.landInfoIds
      const arr = []
      landInfoIds.map(item => {
        arr.push(item.id)
      })
      data.landInfoIds = arr
      data.userId = this.$store.state.userInfo.id
      return data
    },
    submitSuccess () {
      if (this.pageType === 'add') {
        this.$message.success('新增成功！')
      } else {
        this.$message.success('修改成功！')
      }
      this.$router.back()
    },

    getMap () {
      this.showMapDialog = true
    },
    setLngAndLat (val) {
      this.form.longitude = val.lng
      this.form.latitude = val.lat
      this.showMapDialog = false
      console.log(this.areaValue,'aaaaaaaa啊啊')
    },

    itude (rule, value, callback) {
      if (!this.form.longitude) {
        callback(new Error('项目经度不能为空'))
      } else if (!this.form.latitude) {
        callback(new Error('项目纬度不能为空'))
      } else {
        callback()
      }
    },
    onSelect () {
      this.isShow = true
    },
    /**
     * @description: 根据ID查询确权详情
     * @param {Number} id
     * @return {void}
     */
    async handleGetRightFormData (id) {
      const params = { id }
      const res = await this.$axios.get(getRightInfo, { params })
      if (res.status === 100) {
        const data = res.data
        data.houseId = this.houseId
        this.form = data
        // this.form = res.data
        this.form.contractBeginTime = this.form.contractBeginTime.replace(/[\u4e00-\u9fa5]/g, '-')
        this.form.contractEndTime = this.form.contractEndTime.replace(/[\u4e00-\u9fa5]/g, '-')
        this.form.contractBeginTime = this.form.contractBeginTime.substring(0,this.form.contractBeginTime.length-1)
        this.form.contractEndTime = this.form.contractEndTime.substring(0,this.form.contractEndTime.length-1)
        this.searchParams.communityId = res.data.communityId
        const ids = res.data.landInfoIds
        this.checkSearchParams.landInfoIds = JSON.stringify(ids)
        const landInfoIds = []
        if (this.form.siteNameList.length === 0) {
          this.form.landInfoIds = []
        }
        res.data.landInfoIds && res.data.landInfoIds.map((item, index) => {
          const obj = {}
          obj.id = item
          obj.text = res.data.siteNameList[index].blockOfCode
          obj.siteName = res.data.siteNameList[index].blockOfCode
          obj.obj = res.data.siteNameList[index]
          landInfoIds.push(obj)
        })
        this.form.landInfoIds = landInfoIds
        this.selectedData = landInfoIds
        if (this.pageType === 'check') {
          const { contractAreaHectare, contractAreaHao, contractAreaLi, contractAreaFen, contractAreaMu } = res.data
          const Hectare = (contractAreaHectare && Number(contractAreaHectare) !== 0) ? Number(contractAreaHectare) + ' 公顷 ' : ''
          const Mu = (contractAreaMu && Number(contractAreaMu) !== 0) ? Number(contractAreaMu) + ' 亩 ' : ''
          const Fen = (contractAreaFen && Number(contractAreaFen) !== 0) ? Number(contractAreaFen) + ' 分 ' : ''
          const Li = (contractAreaLi && Number(contractAreaLi) !== 0) ? Number(contractAreaLi) + ' 厘 ' : ''
          const Hao = (contractAreaHao && Number(contractAreaHao) !== 0) ? Number(contractAreaHao) + ' 毫 ' : ''
          this.form.contractArea = Hectare + Mu + Fen + Li + Hao
        }
      }
    },
    success (list, val) {
      let data = [...list], obj = {}, arr = []
      data.map(item => {
        const { id, siteName } = item
        const { blockOfCode } = item.obj
        obj.id = id
        obj.siteName = blockOfCode
        obj.text = blockOfCode
        arr.push(obj)
        obj = {}
      })
      this.form.landInfoIds = arr
      // 动态计算
      this.calcLandInfo(list)
      this.selectedData = list
    },
    close () {},
    selectable (data) {
      return data.authenticRight === 0
    },
    onChange (data) {
      if (this.mount) {
        this.mount = false
        return
      }
      const list = []
      this.selectedData.map(item => {
        data.map(_item => {
          if (item.id === _item.id) {
            item.siteName = item.text
            list.push(item)
          }
        })
      })
      this.selectedData = list
      this.calcLandInfo(list)
    },
    calcLandInfo (list) {
      // 动态计算
      this.form.contractNum = list.length
      let contractAreaHectare = 0,
          contractAreaMu = 0,
          contractAreaFen = 0,
          contractAreaLi = 0,
          contractAreaHao = 0

      list.map(item => {
        contractAreaHectare = this.numAdd(contractAreaHectare, Number(item.obj.madeByAreaHectare))
        // contractAreaHectare += Number(item.obj.madeByAreaHectare)
        contractAreaMu = this.numAdd(contractAreaMu, Number(item.obj.madeByAreaMu))
        contractAreaFen = this.numAdd(contractAreaFen, Number(item.obj.madeByAreaFen))
        contractAreaLi = this.numAdd(contractAreaLi, Number(item.obj.madeByAreaLi))
        contractAreaHao = this.numAdd(contractAreaHao, Number(item.obj.madeByAreaHao))

        // contractAreaMu += Number(item.obj.madeByAreaMu)
        // contractAreaFen += Number(item.obj.madeByAreaFen)
        // contractAreaLi += Number(item.obj.madeByAreaLi)
        // contractAreaHao += Number(item.obj.madeByAreaHao)
      })
      this.form.contractAreaHectare = contractAreaHectare
      this.form.contractAreaMu = contractAreaMu
      this.form.contractAreaFen = contractAreaFen
      this.form.contractAreaLi = contractAreaLi
      this.form.contractAreaHao = contractAreaHao
    },
    numAdd (num1, num2) { 
      var baseNum, baseNum1, baseNum2; 
      try { 
        baseNum1 = num1.toString().split(".")[1].length; 
      } catch (e) { 
        baseNum1 = 0; 
      } 
      try { 
        baseNum2 = num2.toString().split(".")[1].length; 
      } catch (e) { 
        baseNum2 = 0; 
      } 
      baseNum = Math.pow(10, Math.max(baseNum1, baseNum2)); 
      return (num1 * baseNum + num2 * baseNum) / baseNum; 
    },
    
    // 用户
    userChange(data) {
      console.log(this.form);
      if (data) {
        this.placeholder = '请输入户主'
        this.nameDisabled = false
        const { communityId, address, houseId, userName, id, mobileNum } = data
        this.form.communityId = communityId;
        this.form.houseId = houseId;
        this.form.houseAddress = address;
        this.form.userId1 = id;
        this.form.mobileNum = mobileNum;
        this.form.userName = userName;
        this.handleGetUserIdNum(id)
        this.searchParams.communityId = communityId
        this.extraParams = { communityId:  this.searchParams.communityId }
      } else {
        this.form.communityId = '';
        this.form.houseId = '';
        this.form.houseAddress = '';
        this.form.contractor = '';
        this.form.cardNum = '';
        this.$refs.select2.clearValue()
      }
    },
    // 查询身份证号码
    async handleGetUserIdNum (id) {
      const res = await this.$axios.get(getIdNum, { params: { id } })
      if (res.status === 100) {
        console.log(res);
      }
    },
    handleCommunityChange (data) {
      console.log(data);
      if (data) {
        this.form.contractor = data.name
      }
    },
    calcArea (row) {
      const { madeByAreaHectare, madeByAreaHao, madeByAreaLi, madeByAreaFen, madeByAreaMu } = row
      const Hectare = (madeByAreaHectare && Number(madeByAreaHectare) !== 0) ? Number(madeByAreaHectare) + ' 公顷 ' : ''
      const Mu = (madeByAreaMu && Number(madeByAreaMu) !== 0) ? Number(madeByAreaMu) + ' 亩 ' : ''
      const Fen = (madeByAreaFen && Number(madeByAreaFen) !== 0) ? Number(madeByAreaFen) + ' 分 ' : ''
      const Li = (madeByAreaLi && Number(madeByAreaLi) !== 0) ? Number(madeByAreaLi) + ' 厘 ' : ''
      const Hao = (madeByAreaHao && Number(madeByAreaHao) !== 0) ? Number(madeByAreaHao) + ' 毫 ' : ''
      return Hectare + Mu + Fen + Li + Hao
    },
    contractTime (rule, value, callback) {
      if (!this.form.contractBeginTime || !this.form.contractEndTime) {
        callback(new Error('请输入承包期限'))
      } else {
        callback()
      }
    },
    echohouseId (data) {
      let newData = {
        userId: `{"userId":"${this.form.userId}","houseId":"${this.form.houseId}"}`
      }
      return newData
    },
  }
}
</script>
<style lang="scss" scoped>
.rightInfoForm-container {
  .area-ruler {
    margin: 0 10px;
  }
  .check-wrapper {
    margin: 0 20px;
    ::v-deep .text {
      text-align: left;
    }
    .check-back {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  ::v-deep .el-input.is-disabled .el-input__inner {
    color: #000;
  }
  ::v-deep .multip-container {
    width: 350px;
  }
}
</style>
