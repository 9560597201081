var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "rightInfoForm-container" },
    [
      _vm.pageType !== "check"
        ? _c(
            "form-panel",
            _vm._b(
              {
                ref: "formPanel",
                staticClass: "form-container",
                attrs: {
                  form: _vm.form,
                  submitUrl: _vm.submitUrl,
                  submitText: "保存",
                  submitBefore: _vm.submitBefore,
                  submitSuccess: _vm.submitSuccess,
                },
              },
              "form-panel",
              _vm.submitConfig,
              false
            ),
            [
              _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "基本信息" } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "户主",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属项目",
                                trigger: "blur",
                              },
                            ],
                            prop: "communityId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                attrs: {
                                  placeholder: _vm.placeholder,
                                  disabled: _vm.nameDisabled,
                                  echoRequestData: _vm.echohouseId,
                                  width: 350,
                                },
                                on: { onChange: _vm.userChange },
                                model: {
                                  value: _vm.form.houseId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "houseId", $$v)
                                  },
                                  expression: "form.houseId",
                                },
                              },
                              "v-select2",
                              _vm.userParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "所属项目",
                            rules: [
                              {
                                required: true,
                                message: "请选择所属项目",
                                trigger: "blur",
                              },
                            ],
                            prop: "communityId",
                          },
                        },
                        [
                          _c(
                            "v-select2",
                            _vm._b(
                              {
                                ref: "select2",
                                attrs: {
                                  width: 350,
                                  subjoinChangeClear: true,
                                  disabled: "",
                                  placeholder: "查询项目",
                                },
                                on: { onChange: _vm.handleCommunityChange },
                                model: {
                                  value: _vm.form.communityId,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "communityId", $$v)
                                  },
                                  expression: "form.communityId",
                                },
                              },
                              "v-select2",
                              _vm.communityParams,
                              false
                            )
                          ),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "手机号码",
                            rules: [
                              {
                                required: false,
                                message: "请输入身份证号码",
                                trigger: "blur",
                              },
                            ],
                            prop: "cardNum",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: { width: 350, disabled: "" },
                            model: {
                              value: _vm.form.mobileNum,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "mobileNum", $$v)
                              },
                              expression: "form.mobileNum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承包家庭",
                            rules: [
                              {
                                required: true,
                                message: "请输入承包家庭",
                                trigger: "blur",
                              },
                            ],
                            prop: "houseAddress",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: { width: 350, disabled: "" },
                            model: {
                              value: _vm.form.houseAddress,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "houseAddress", $$v)
                              },
                              expression: "form.houseAddress",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "发包方",
                            rules: [
                              {
                                required: true,
                                message: "请选择发包方",
                                trigger: "blur",
                              },
                            ],
                            prop: "contractor",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: { width: 350, disabled: "" },
                            model: {
                              value: _vm.form.contractor,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractor", $$v)
                              },
                              expression: "form.contractor",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承包方式",
                            rules: [
                              {
                                required: true,
                                message: "请选择承包方式",
                                trigger: "blur",
                              },
                            ],
                            prop: "contractorType",
                          },
                        },
                        [
                          _c("v-select", {
                            attrs: { width: 350, options: _vm.contractType },
                            model: {
                              value: _vm.form.contractorType,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractorType", $$v)
                              },
                              expression: "form.contractorType",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "合同代码",
                            rules: [
                              {
                                required: true,
                                message: "请输入合同代码",
                                trigger: "blur",
                              },
                            ],
                            prop: "contractCode",
                          },
                        },
                        [
                          _c("v-input", {
                            attrs: {
                              width: 350,
                              placeholder: "请输入合同代码",
                            },
                            model: {
                              value: _vm.form.contractCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "contractCode", $$v)
                              },
                              expression: "form.contractCode",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承包期限",
                            rules: [
                              {
                                required: true,
                                message: "请输入承包期限",
                                validator: _vm.contractTime,
                                trigger: "blur",
                              },
                            ],
                            prop: "contractTime",
                          },
                        },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              startTime: _vm.form.contractBeginTime,
                              endTime: _vm.form.contractEndTime,
                            },
                            on: {
                              "update:startTime": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "contractBeginTime",
                                  $event
                                )
                              },
                              "update:start-time": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "contractBeginTime",
                                  $event
                                )
                              },
                              "update:endTime": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "contractEndTime",
                                  $event
                                )
                              },
                              "update:end-time": function ($event) {
                                return _vm.$set(
                                  _vm.form,
                                  "contractEndTime",
                                  $event
                                )
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承包总面积",
                            rules: [
                              {
                                required: true,
                                message: "请输入承包总面积",
                                trigger: "blur",
                              },
                            ],
                            prop: "contractAreaHectare",
                          },
                        },
                        [
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.form.contractAreaHectare
                                  ? _vm.form.contractAreaHectare + " 公顷 "
                                  : ""
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.form.contractAreaMu
                                  ? _vm.form.contractAreaMu + " 亩 "
                                  : ""
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.form.contractAreaFen
                                  ? _vm.form.contractAreaFen + " 分 "
                                  : ""
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.form.contractAreaLi
                                  ? _vm.form.contractAreaLi + " 厘 "
                                  : ""
                              )
                            ),
                          ]),
                          _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.form.contractAreaHao
                                  ? _vm.form.contractAreaHao + " 毫 "
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承包总块数",
                            rules: [
                              {
                                required: true,
                                message: "请输入承包总块数",
                                trigger: "blur",
                              },
                            ],
                            prop: "contractNum",
                          },
                        },
                        [
                          _c("div", [
                            _vm._v(
                              _vm._s(
                                _vm.form.contractNum
                                  ? _vm.form.contractNum + " 块"
                                  : ""
                              )
                            ),
                          ]),
                        ]
                      ),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "承包地块信息",
                            rules: [
                              {
                                required: true,
                                message: "请选择承包地块信息",
                                trigger: "blur",
                              },
                            ],
                            prop: "landInfoIds",
                          },
                        },
                        [
                          _c("chosen-list-panel", {
                            attrs: {
                              list: _vm.form.landInfoIds,
                              disabled: !_vm.form.houseId,
                            },
                            on: {
                              "update:list": function ($event) {
                                return _vm.$set(_vm.form, "landInfoIds", $event)
                              },
                              select: _vm.onSelect,
                              change: _vm.onChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            "div",
            { staticClass: "check-wrapper" },
            [
              _c("v-button", {
                staticClass: "check-back",
                attrs: { text: "返回", type: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$router.back()
                  },
                },
              }),
              _c(
                "col2-detail",
                [
                  _c(
                    "col2-block",
                    { attrs: { title: "确权信息" } },
                    [
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "所属项目",
                          text: _vm.form.communityName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "户主",
                          text: _vm.form.userName,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "手机号码",
                          text: _vm.form.mobileNum,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包家庭",
                          text: _vm.form.houseAddress,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "发包方",
                          text: _vm.form.contractor,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包方式",
                          text: _vm.contractorTypeMap[_vm.form.contractorType],
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "合同代码",
                          text: _vm.form.contractCode,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包期限",
                          text: `${
                            _vm.form.contractBeginTime
                              ? _vm.form.contractBeginTime + " 至 "
                              : ""
                          }${_vm.form.contractEndTime}`,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包总面积（亩）",
                          text: _vm.form.contractArea,
                        },
                      }),
                      _c("col2-item", {
                        attrs: {
                          span: 24,
                          label: "承包总块数（块）",
                          text: _vm.form.contractNum,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "col2-block",
                    { attrs: { title: "承包地块信息" } },
                    [
                      _c(
                        "el-table",
                        {
                          staticClass: "table",
                          staticStyle: { width: "70%" },
                          attrs: { data: _vm.form.siteNameList, border: "" },
                        },
                        [
                          _c("el-table-column", {
                            attrs: {
                              prop: "communityName",
                              label: "所属项目",
                              width: "100",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "blockOfCode",
                              label: "地块代码",
                              width: "180",
                            },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "siteName", label: "地块名称" },
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "plotCategory", label: "地块类型" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.landTypeMap[
                                            Number(scope.row.plotCategory)
                                          ]
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "madeByArea", label: "面积" },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(_vm._s(_vm.calcArea(scope.row))),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                          _c("el-table-column", {
                            attrs: { prop: "beLocated", label: "坐落（四至）" },
                          }),
                          _c("el-table-column", {
                            attrs: {
                              prop: "authenticRight",
                              label: "确权状态",
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "default",
                                fn: function (scope) {
                                  return [
                                    _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          scope.row.authenticRight === 0
                                            ? "未确权"
                                            : "已确权"
                                        )
                                      ),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
      _c("multi-select", {
        ref: "multiSelect",
        attrs: {
          isShow: _vm.isShow,
          responseKey: _vm.responseKey,
          searchUrl: _vm.searchUrl,
          backFill: _vm.selectedData,
          searchParams: _vm.searchParams,
          responseParams: _vm.responseParams,
          extraParams: _vm.extraParams,
          selectable: _vm.selectable,
          isMultiSelect: true,
          headers: _vm.headers,
          title: "项目列表",
          "update:backFill": "backFill",
        },
        on: {
          "update:isShow": function ($event) {
            _vm.isShow = $event
          },
          "update:is-show": function ($event) {
            _vm.isShow = $event
          },
          callback: _vm.success,
          close: _vm.close,
        },
        scopedSlots: _vm._u([
          {
            key: "searchSlot",
            fn: function () {
              return [
                _c(
                  "v-select2",
                  _vm._b(
                    {
                      attrs: { label: "所属项目", width: 180 },
                      model: {
                        value: _vm.searchParams.communityId,
                        callback: function ($$v) {
                          _vm.$set(_vm.searchParams, "communityId", $$v)
                        },
                        expression: "searchParams.communityId",
                      },
                    },
                    "v-select2",
                    _vm.communityParams,
                    false
                  )
                ),
                _c("v-input", {
                  attrs: { label: "地块代码" },
                  model: {
                    value: _vm.searchParams.blockOfCode,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "blockOfCode", $$v)
                    },
                    expression: "searchParams.blockOfCode",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "地块名称" },
                  model: {
                    value: _vm.searchParams.siteName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "siteName", $$v)
                    },
                    expression: "searchParams.siteName",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "地块类型", options: _vm.landType },
                  model: {
                    value: _vm.searchParams.plotCategory,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "plotCategory", $$v)
                    },
                    expression: "searchParams.plotCategory",
                  },
                }),
                _c("v-select", {
                  attrs: { label: "确权状态", options: _vm.RightConfirStatus },
                  model: {
                    value: _vm.searchParams.authenticRight,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "authenticRight", $$v)
                    },
                    expression: "searchParams.authenticRight",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "户主" },
                  model: {
                    value: _vm.searchParams.userName,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "userName", $$v)
                    },
                    expression: "searchParams.userName",
                  },
                }),
                _c("v-input", {
                  attrs: { label: "承包家庭" },
                  model: {
                    value: _vm.searchParams.houseAddress,
                    callback: function ($$v) {
                      _vm.$set(_vm.searchParams, "houseAddress", $$v)
                    },
                    expression: "searchParams.houseAddress",
                  },
                }),
                _c("v-datepicker", {
                  attrs: {
                    label: "创建时间",
                    startTime: _vm.searchParams.intimeStart,
                    endTime: _vm.searchParams.intimeEnd,
                    appendToBody: _vm.appendToBody,
                  },
                  on: {
                    "update:startTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "intimeStart", $event)
                    },
                    "update:start-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "intimeStart", $event)
                    },
                    "update:endTime": function ($event) {
                      return _vm.$set(_vm.searchParams, "intimeEnd", $event)
                    },
                    "update:end-time": function ($event) {
                      return _vm.$set(_vm.searchParams, "intimeEnd", $event)
                    },
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c("v-map", {
        attrs: {
          selectedLng: Number(_vm.form.longitude),
          selectedLat: Number(_vm.form.latitude),
          showMapDialog: _vm.showMapDialog,
        },
        on: {
          "update:showMapDialog": function ($event) {
            _vm.showMapDialog = $event
          },
          "update:show-map-dialog": function ($event) {
            _vm.showMapDialog = $event
          },
          setLngAndLat: _vm.setLngAndLat,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }