import { generateMapByOpts } from '@/common/utils'

const contractType = [
  {
    text: '家庭承包',
    value: 1
  }
]
// 地块类型
const landType = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '耕地',
    value: 1
  },
  {
    text: '林地',
    value: 2
  },
  {
    text: '草地',
    value: 3
  },
  {
    text: '农田水利用地',
    value: 4
  },
  {
    text: '养殖水面',
    value: 5
  },
  {
    text: '宅基地',
    value: 6
  },
  {
    text: '公共设施用地',
    value: 7
  },
  {
    text: '交通水利设施用地',
    value: 8
  },
  {
    text: '旅游用地',
    value: 9
  }
]

// 确权状态
const RightConfirStatus = [
  {
    text: '全部',
    value: undefined
  },
  {
    text: '已确权',
    value: 1
  },
  {
    text: '未确权',
    value: 0
  }
]
const contractorTypeMap = {
  1: '家庭承包'
}
const landTypeMap = generateMapByOpts(landType)
const contractMap = generateMapByOpts(contractType)

export {
  contractType,
  contractMap,
  landType,
  landTypeMap,
  RightConfirStatus,
  contractorTypeMap
}
